<template>
  <div>
    <v-snackbar
      v-for="(alert, index) in alerts"
      :key="index"
      :timeout="8000"
      :value="true"
      :color="'bg-' + alert.type"
      top
      right
      transition="slide-y-transition"
    >
      <span class="font-size-lg font-weight-bold">
        <v-icon v-if="alert.type === 'success'" class="mr-4">
          mdi-check-bold
        </v-icon>
        {{ alert.message }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "NotificationPanel",
  computed: {
    alerts() {
      return this.$store.getters.alerts;
    }
  }
};
</script>

<style scoped></style>
