<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left d-flex aside-fixed border-left-primary shadow"
    style="border-left: 8px solid"
    id="kt_aside"
    ref="kt_aside"
  >
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Dashboard'"
          >
            <router-link
              to="/dashboard"
              class="nav-link btn btn-icon btn-clean btn-lg"
              :class="menuDisabledClass"
              active-class="active"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg
                  src="media/svg/icons/Shopping/Chart-bar1.svg"
                  title=""
                />
                <!--end::Svg Icon-->
              </span>
            </router-link>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Karten Übersicht'"
          >
            <router-link
              to="/addresses"
              class="nav-link btn btn-icon btn-clean btn-lg"
              :class="menuDisabledClass"
              active-class="active"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Map/Direction1.svg" title="" />
                <!--end::Svg Icon-->
              </span>
            </router-link>
          </li>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
      >
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Logout'"
          >
            <button
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              @click="logout"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl svg-fill-dark">
                <!--begin::Svg Icon-->
                <inline-svg
                  src="media/svg/icons/Navigation/Sign-out.svg"
                  title=""
                />
                <!--end::Svg Icon-->
              </span>
            </button>
          </li>
          <!--end::Item-->
        </ul>
        <!--begin::Aside Toggle-->
        <!--<span
          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>-->
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!--<KTQuickActions></KTQuickActions>-->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!--<KTQuickPanel></KTQuickPanel>-->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <!--<KTQuickUser></KTQuickUser>-->
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import { LOGOUT } from "@/core/services/store/auth.module";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
// import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0
    };
  },
  components: {
    KTBrand
    // KTQuickActions,
    // KTQuickUser,
    // KTQuickPanel
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }))
        .catch(() => {});
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    menuDisabled() {
      return (
        this.$store.getters.pageTitle === "Applikation-Setup" &&
        this.$store.getters.loadedAddressesCount <
          this.$store.getters.maxAddressesCount
      );
    },
    menuDisabledClass() {
      return {
        disabled: this.menuDisabled
      };
    }
  }
};
</script>
